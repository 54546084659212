import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Banner } from './style'

import Nav from '../../components/Nav'

export default function Index(){
  return(
    <>
    <Nav/>
      <Container>
        <Banner ></Banner>
        <div className="lista">
        <div className="coluna2" style={{ width: '18%', height: '450px', cursor: 'initial' }}></div>
          <div className="coluna" style={{ backgroundImage: 'url("https://imgur.com/pOxHw1E.png")', backgroundSize: 'cover' }}>
          <a href="https://tophoras.unknowncommunity.ptts3.pt/" target="_blank" rel="noopener noreferrer">Top Horas</a>
          <a href="https://bans.unknowncommunity.ptts3.pt/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: 110 }}>Bans</a>
          </div>
          <Link to='/fivem'>
          <div className="coluna" style={{ backgroundImage: 'url("https://imgur.com/0m3BNQF.png")', backgroundSize: 'cover' }}>
           <a>FiveM</a>
          </div>
          </Link>
          <div className="coluna2" style={{ width: '18%', height: '450px', cursor: 'initial'  }}></div>
        </div>
      </Container>
    </>
  );
}
import React from "react"
import { Link } from 'react-router-dom'

import { FaUser } from 'react-icons/fa';
import { Headers, Inner_header, Cona_container, Right } from "./style";

import { isAuthenticated } from '../../services/auth';

const Index = (active) => (
  <Headers>
      <Inner_header>
      <Link to="/">
      <Cona_container>
      <h1>unknown community</h1>
      </Cona_container>
      </Link>
      <Right>
      <Link to="/staff"><a className="item">sobre nós</a></Link>
      <Link to={isAuthenticated() ? "/dashboard" : '/login'}><a href="#" className="area"><FaUser/> Área de Jogador</a></Link>
      </Right>
      </Inner_header>
      </Headers>
)

export default Index

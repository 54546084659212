import api from './api';
import jwt from 'jsonwebtoken';
import jwtConfig from '../config/auth.json';
import { useHistory } from 'react-router';
import { store } from 'react-notifications-component';
//
export const TOKEN_KEY = "@unknown";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export default async function Verify() {
  let history = useHistory()
  jwt.verify(localStorage.getItem(TOKEN_KEY), jwtConfig.secret, (err, decoded) => {
    if (err) {
      history.push('/login')

      if (isAuthenticated()){
        logout()
      }
    }
  });
}
import styled from 'styled-components';

export const Container = styled.div`
position: absolute;
  width: 100%;
  height: 120vh;
  background: linear-gradient(162deg, rgba(0,0,0,1) 0%, rgba(27,27,27,1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .lista{
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 650px;
    padding-left: 50px;
  }
  
  .lista .coluna{
    display: inline-block;
    background: rgb(40, 40, 40, 0.1);
    color: #fff;
    width: 400px;
    height: 500px;
    border-radius: 5px;
    margin-left: 90px;
    //transition: all 0.2s ease 0s;;
    transition: all 0.5s;
    box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.40);
  }
  
  .lista .coluna2{
    display: inline-block;
    background: rgb(40, 40, 40, 0.25);
    color: #fff;
    backdrop-filter: blur(1px);
    width: 400px;
    height: 500px;
    border-radius: 5px;
    margin-left: 90px;
    margin-bottom: 25px;
    transition: all 0.2s ease 0s;
    box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.40);
  }

  @media only screen and (max-width: 768px) {
    
    .lista {
      padding-top: 100px;
    }

    .lista .coluna2 {
      display: none;
    }
    
    .lista .coluna {
      display: block;
      width: 65%;
      margin: 0 auto;
      margin-bottom: 5px;
    }

    }

  @media only screen and (max-width: 1400px) {
    
    .lista {
      padding-top: 100px;
    }

    .lista .coluna2 {
      display: none;
    }
    
    .lista .coluna {
      display: block;
      width: 80%;
      height: 47%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    }


  .lista .coluna a{
    text-decoration: none;
    position: absolute;
    border: 0;
    background: rgb(19, 19, 19, 0.4);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: 15px;
    margin-left: 15px;
    transition: all 0.2s ease 0s;;
    letter-spacing: 1px;
    font-size: 15px;
  }

  .lista .coluna a:hover{
    background: rgb(19, 19, 19, 0.6);
  }

  .lista .coluna h1{
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 15px;
    opacity: 0.6;
  }

  .lista .coluna:hover{
    transform: scale(1.05); /* (150% zoom)*/
    background-size: cover;
    cursor: pointer;
  }

`;

export const Banner = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  background-image: url('https://imgur.com/0iDK07E.png');
  background-size: cover;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  top: 6.3%;
  box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.50);

  h1{
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }

`;





import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: block;
  color: #fff;
  background: rgb(40, 40, 40, 0.7);
  width: 50px;
  padding: 17px;
  height: 50px;
  top: 3%;
  left: 2%;
  border-radius: 5px;

  &:hover{
    background: rgb(40, 40, 40, 0.8);
    cursor: pointer;
  }

`;


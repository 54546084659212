import React, {useEffect, useState} from 'react';
import { Container, Table } from './style'

import api from '../../services/api';
import { getToken } from '../../services/auth';

import Left from '../../components/Left'

import { FaPlus } from 'react-icons/fa'

export default function Index(){
  const [candidaturas, setcandidaturas] = useState([]);
  useEffect(() => {
    api.get(`/project/candidaturas`, {
      headers: {
        'Authorization': `Bearer ${getToken()}` 
      }
    })
    .then(res => {
      if (res) {
        setcandidaturas(res.data);
      } 
    })
  }, [setcandidaturas])

  function state(state){
    if (state === 1) {
      return <span className="pending" title="Pendente"/>
    } else if (state === 2) {
      return <span className="accepted" title="Aceitada"/>
    } else if (state === 3) {
      return <span className="denieded" title="Recusada"/>
    }
  }

  function is_denied(state){
    if (state === 3) {
      return 0.4;
    } else if (state === 2) {
      return 0.4
    }
  }

  return(
    <>
    <Container>
      <Left/>
      <button className="button"><FaPlus/></button>
      <Table>
        <div className="top">
        </div>

        {candidaturas.length >= 1 ? 
        <>
        {candidaturas.map((candidatura) => (
          <>
          <div className="lista" style={{ opacity: is_denied(candidatura.status) }}>
            <a>
            {candidatura.name}
            </a>
            <a style={{ marginLeft: 100 }}>
            {candidatura.type}
            </a>
            <a style={{ marginLeft: 100, opacity: is_denied(candidatura.status) }}>
            {state(candidatura.status)}
            </a>
          </div>
          </>
        ))}
        </>
        :
        <h1 style={{ color: '#fff', margin: '0 auto', textAlign: 'center', fontSize: 16, opacity: 0.6, textTransform: 'uppercase',  letterSpacing: '3px'}}>
          Sem Candidaturas
        </h1>
        }
        
      </Table>
    </Container>
    </>
  );
}
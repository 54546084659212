import React from 'react';
import Typed from 'react-typed';

import { Link } from 'react-router-dom'
import { Header, Aboutus, Partners } from './style';
import { isAuthenticated } from '../../services/auth'

import Back from '../../components/Back'

export default function Index(){
  return(
    <>
      <Header>
        <Back/>
        <div className="mid">
          <div className="logo">
            <div className="welcome animated fadeIn">Bem-vindo, Jogador</div>
              <div className="welcome-2 animated fadeIn">Estás pronto para entrar em algo <Typed strings={["revolucionário?", "diferente?"]} typeSpeed={350} backSpeed={150} loop/></div>
              <div className="welcome-2 animated fadeIn"><Link to={isAuthenticated() ? "/dashboard" : '/login'}><button>Área de Jogador</button></Link></div>
              <div></div>
          </div>
        </div>
      </Header>
      <Aboutus id="Aboutus">
        <div className="header">
          <h1>SOBRE NÓS</h1>
        </div>
        <div className="footer">
          <p>Sê bem vindo a um dos projetos mais promissores e sérios a nível do Roleplay em Portugal.<br/>
          Este é o Website de candidaturas da comunidade, lê as regras e faz já a tua candidatura. <br/>
          O servidor encontra se em desenvolvimento por isso fica atento ao discord e vai vendo os updates.<br/>
          <br/>Cumprimentos, Equipa <strong>Unknown Community</strong></p>
        </div>
      </Aboutus>
      <Partners>
        <div className="header">
          <div className="block">
            <h1>QUERES FAZER PARCERIA?</h1>
            <p>
              Contacta o nosso <b>departamento de parcerias</b> no nosso discord.
            </p>
          </div>
        </div>
      </Partners>
    </>
  );
}
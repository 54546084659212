import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(162deg, rgba(0,0,0,1) 0%, rgba(27,27,27,1) 100%);
  display: flex;
  justify-content: space-between;

  .button {
    position: absolute;
    color: #fff;
    font-size: 16px;
    background: rgb(19, 19, 19, 0.4);
    height: 45px;
    border: 0;
    border-radius: 5px;
    width: 50px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    left: 78.5%;
    top: 18%;
  }

  .button:hover{
    opacity: 0.8;
  }

`;

export const Table = styled.div`
  width: 50%;
  height: 30%;
  background: rgb(19, 19, 19, 0.4);
  margin: 0 auto;
  margin-bottom: 340px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.40);
  margin-top: 260px;

  .top {
    height: 10px;
    margin-bottom: 50px;
  }

  .pending {
    float: right;
    height: 10px;
    padding: 10px;
    border-radius: 5px;
    background: red;
    width: 150px;
    background: rgb(237, 148, 19);
    box-shadow:
    0 0 7px rgb(237, 148, 19);
  }

  .accepted {
    float: right;
    height: 10px;
    padding: 10px;
    border-radius: 5px;
    background: red;
    width: 150px;
    background: rgb(19, 237, 98);
    box-shadow:
    0 0 7px rgb(19, 237, 98);
  }

  .denieded {
    float: right;
    height: 10px;
    padding: 10px;
    border-radius: 5px;
    background: red;
    width: 150px;
    background: rgb(237, 19, 30);
    box-shadow:
    0 0 7px rgba(237, 19, 30, 0.5);
  }

  .lista {
    background: rgb(19, 19, 19, 0.6);
    color: #fff;
    height: 60px;
    margin-bottom: 30px;
    padding: 20px;
  }

  .lista .text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }

  .lista span{
    margin-left: 20px;
    width: 100px;
  }

`;





import styled from 'styled-components';

export const Container = styled.div`
position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(162deg, rgba(0,0,0,1) 0%, rgba(27,27,27,1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .banner {
  position: relative;
  width: 100%;
  height: 10vh;
  text-align: center;
  color: #fff;
  }

  .banner .mid{
    position: relative;
    top: 50%;
    transform: translateY(-50%);  
  }


  .lista{
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-top: 250px;
  }
  
  .lista .coluna{
    display: inline-block;
    background: rgb(40, 40, 40, 0.1);
    color: #fff;
    width: 15%;
    height: 300px;
    border-radius: 5px;
    margin-left: 50px;
    transition: all 0.5s;
    box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.40);
  }

  .lista .coluna .bottom{
    text-align: center;
  }

  .lista .coluna .bottom p{
    font-size: 12px;
    opacity: 0.8;
  }

  .lista .coluna .top span{
    padding: 10px;
    background: rgb(19, 19, 19);
    border-radius: 5px;
    position: absolute;
    display: flex;
    color: rgb(231, 178, 91);
    margin-left: -20px;
    margin-top: -10px;
    text-shadow:
    0 0 7px rgb(231, 178, 91);
  }


  .lista .coluna .top{
    width: 100%;
    height: 150px;
    background: rgb(40, 40, 40, 0.1);
  }

  .lista .coluna .bottom .steam-button{
    text-decoration: none;
    border: 0;
    background: rgb(19, 19, 19, 0.4);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    transition: all 0.5s;
    letter-spacing: 1px;
    font-size: 15px;
  }

  .lista .coluna .bottom p{
    padding: 5px;
    margin-top: -15px;
  }
  
  .lista .coluna2{
    display: inline-block;
    background: rgb(40, 40, 40, 0.1);
    color: #fff;
    width: 20%;
    height: 500px;
    border-radius: 5px;
    margin-left: 90px;
    transition: all 0.5s;
  }


  /*.lista .coluna a{
    text-decoration: none;
    position: absolute;
    border: 0;
    background: rgb(19, 19, 19, 0.4);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: 15px;
    margin-left: 15px;
    transition: all 0.5s;
    letter-spacing: 1px;
    font-size: 15px;
  }*/

  .lista .coluna a:hover{
    background: rgb(19, 19, 19, 0.6);
  }

  .lista .coluna h1{
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 15px;
    opacity: 0.6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  .lista .coluna:hover{
    transform: scale(1.05); /* (150% zoom)*/
    background-size: cover;
    cursor: pointer;
  }

`;

export const Nav = styled.ul`
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: rgba(40, 40, 40, 0.1);
  font-weight: bold;
  width: 100%;
  height: 100px;
  text-align: left;
  padding: 40px;
`;

export const Item = styled.a`
  display: inline-block;
  width: 135px;
  height: 50px;
  padding: 13px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: -15px;
  background: rgba(204, 81, 81, 0.5);
  margin-left: 15px;
  letter-spacing: 2px;
  border: 0;
  transition: all 0.5s;
  cursor: initial;

  &:hover{
  background: rgba(204, 81, 81, 0.7);
  cursor: pointer;
  }
`;

export const Item_Text = styled.a`
  text-decoration: none;
  color: #fff;
  margin-left: 5px;
`;




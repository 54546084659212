import React, { useState, useEffect } from 'react';
import api from '../../services/api'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Container } from './style'

import Nav from '../../components/Nav'

export default function Index(){
  const [fundadores, setfundadores] = useState([])

  useEffect(() => {
    api.get(`/data/staff`)
    .then(res => {
      if (res) {
        setfundadores(res)
      } 
    })
  }, [setfundadores])

  return(
    <>
      <Nav/>
      <Container>
      <div className="lista">
        <center>
          {fundadores.data ? 
            <>
          {fundadores.data.map(fundador => (
          <div className="coluna">
            <div className="top">
              <span>Fundador</span>
              <img src={fundador.avatar.large} alt="" style={{ borderRadius: '100%', width: 130, marginTop: 10}} />
            </div>
            <div className="bottom">
              <center>
              <a href={fundador.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'grey' }}>
              <h1>{fundador.nickname}</h1>
              </a>
              </center>
            </div>
          </div>
          ))}
          </>
          : 
          <>
          <div className="coluna">
            <Skeleton height={150} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/>
            <div className="bottom">
              <center>
              <h1><Skeleton width={100} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/></h1>
              </center>
            </div>
          </div>
          <div className="coluna">
            <Skeleton height={150} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/>
            <div className="bottom">
              <center>
              <h1><Skeleton width={100} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/></h1>
              </center>
            </div>
          </div>
          <div className="coluna">
            <Skeleton height={150} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/>
            <div className="bottom">
              <center>
              <h1><Skeleton width={100} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/></h1>
              </center>
            </div>
          </div>
          <div className="coluna">
            <Skeleton height={150} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/>
            <div className="bottom">
              <center>
              <h1><Skeleton width={100} baseColor={"transparent"} highlightColor="rgb(53, 53, 53, 0.2)"/></h1>
              </center>
            </div>
          </div>
          </>
          }
          <>
          </>
        </center>
      </div>
      </Container>
    </>
  );
}
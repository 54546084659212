import React, {useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import ReactNotification from 'react-notifications-component';
import { ClipLoader } from 'react-spinners';
import { store } from 'react-notifications-component';
import { Container, Bg, ContentWrapper, Form } from './style'
import 'react-notifications-component/dist/theme.css';

import { login } from '../../../services/auth'

import Back from '../../../components/Back'

import api from '../../../services/api'

export default function Index(){
  
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [success, setsuccess] = useState(false)
  const history = useHistory();

  async function handleLogin(e){
    e.preventDefault();
    try {
      if (!password || !email) {
        store.addNotification({
          title: "ERRO",
          message: "Preenche todos os dados.",
          type: "danger",
          insert: "bottom",
          container: "bottom-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2500,
            onScreen: true
          }
        });
      }

       await api.post('auth/login', { email, password }).catch(function (err){
          if (email || password) {
            if (err.response.status === 400) {
              store.addNotification({
                title: "ERRO",
                message: err.response.data.error,
                type: "danger",
                insert: "bottom",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 2500,
                  onScreen: true
                }
              });
            }
          }
        }).then((res) => {
          if (res) {
            if (email || password) {
              login(res.data.token)
              setsuccess(true)
              store.addNotification({
                title: `Bem vindo,`,
                message: "Redericionando para a Area de Jogador",
                type: "success",
                insert: "bottom",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3500,
                  onScreen: true
                }
              });
              setTimeout(() => {
                history.push('/dashboard');
              }, 3500);
            }
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  return(
    <>
    <ReactNotification/>
    <Container>
      <Back/>
      <ContentWrapper>
      <Bg>
        <center>
          <Form onSubmit={handleLogin}>
          <>
          <input 
          type="email"
          placeholder="E-mail"
          onChange={e => setemail(e.target.value)}
          />
          </>
          <input 
          type="password"
          placeholder="Senha"
          onChange={e => setpassword(e.target.value)}
          />
          <>
          {!success &&
          <>
          <button type="submit">Entrar</button>
          </>
          }
          {success &&
          <>
          <button type="submit" className="disabled">
          <ClipLoader sizeUnit={"px"} size={40} color={'#fff'} loading={success}/>
          </button>
          </>
          }
          </>
          <Link to="/register" style={{ padding: '15px', fontSize: '13.5px', fontWeight: 'lighter' }}>Não tens uma conta? Faz Registo</Link>
        </Form>
        </center>
      </Bg>
      </ContentWrapper>
      </Container>
    </>
  );
}
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Verify from './services/auth';

import Index from './pages/Index';
import Staff from './pages/Staff';
import FiveM from './pages/FiveM';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Dashboard from './pages/Dashboard';

import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

export default function Routes(){
  Verify()
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index}/>
        <Route path="/staff" component={Staff}/>
        <Route path="/fivem" component={FiveM}/>
        <Route path="/login" component={Login}/>
        <Route path="/register" component={Register}/>
        <PrivateRoute path="/dashboard" component={Dashboard}/>
      </Switch>
    </BrowserRouter>
  )
}
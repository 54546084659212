import React from "react"
import { Link } from 'react-router-dom'
import { Container } from "./style";

import { FaArrowLeft } from 'react-icons/fa'

const Index = (active) => (
  <Link to="/">
    <Container>
      <span><FaArrowLeft/></span>
    </Container>
  </Link>
)

export default Index
